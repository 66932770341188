<template>
  <div
    v-if="getSearchFlightArray"
    class="py-50 px-0 rounded-lg mb-1"
    style="background-color: #F5F5F5"
  >
    <div
      v-for="(flight, indexFlight) of getSearchFlightArray"
      :key="indexFlight"
      class="mb-75"
    >
      <div
        class="rounded-lg py-75 pl-2 fw-700 mb-50"
        style="background-color: #166987; color: white;"
      >
        Hành trình #{{ indexFlight + 1 }}:
        <span class="fw-700">{{ `${flight.startPoint}-${flight.endPoint}` }}</span>
      </div>

      <template v-if="selectedTrips[indexFlight]">
        <!-- ANCHOR - flight has selected -->
        <b-row
          v-for="(segment, indexSegment) in selectedTrips[indexFlight]"
          :key="indexSegment"
          class="mb-75 d-flex"
          no-gutters
        >
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center"
          >
            <span
              :class="`mr-25 mr-md-50 font-weight-bolder
              ${['xs','sm','md'].includes(appBreakPoint) ? 'font-small-4' : 'font-medium-1'}`"
            >
              {{ `${indexFlight + 1}*${indexSegment + 1}` }}
            </span>

            <!-- ANCHOR - Info trip -->
            <b-form-input
              v-if="segment"
              :value="`${getSortTripBySegment(segment, true)}`"
              :class="`text-body fw-700
              ${['xs','sm','md'].includes(appBreakPoint) ? 'font-medium-1' : 'font-medium-2'}
              `"
              style="min-width: 300px;"
              disabled
            />
            <b-form-input
              v-else
              :value="`Chưa chọn chặng ${indexSegment + 1}`"
              class="text-warning font-italic"
              disabled
            />
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-around pl-md-1 mt-50 mt-md-0"
          >
            <!-- ANCHOR - bookingClassCode -->
            <v-select
              v-if="segment && bookingClassCodeSegment[indexFlight] && bookingClassCodeSegment[indexFlight][indexSegment]"
              v-model="bookingClassCodeSegment[indexFlight][indexSegment]"
              :options="classBookingOptions"
              :clearable="false"
              :searchable="false"
              class="select_custom fw-700"
              style="width: 70px !important; background-color: white;"
              @option:selected="(val) => handleChangeClassBooking(val, indexFlight, segment, indexSegment)"
            />
            <!-- FIXME - chỗ này sửa lại nếu chọn vào class nó không có trên hành trình thì không thay đổi classBooking trong store -->
            <b-form-input
              v-else
              style="width: 70px !important;"
              value=""
              disabled
            />

            <!-- ANCHOR - Price total trip -->
            <b-form-input
              v-if="segment"
              :value="`${segment.bookingClass.prices[0].fare && dataTrips[indexFlight] && dataTrips[indexFlight].isDomestic ? formatCurrency(segment.bookingClass.prices[0].fare) : '0'}`"
              class="text-body fw-700 font-medium-2"
              style="width: 140px;"
              disabled
            />
            <b-form-input
              v-else
              value=""
              class="text-warning font-italic"
              style="width: 80px !important;"
              disabled
            />

            <!-- ANCHOR - statusSegment -->
            <v-select
              v-if="segment && statusSegment[indexFlight] && statusSegment[indexFlight][indexSegment]"
              v-model="statusSegment[indexFlight][indexSegment]"
              :options="['NN','LL','DS']"
              :clearable="false"
              :searchable="false"
              class="select_custom fw-700"
              style="width: 80px !important; background-color: white;"
              @input="(val) => handleChangeStatus(val, indexFlight, indexSegment)"
            />
            <b-form-input
              v-else
              style="width: 80px !important;"
              value=""
              disabled
            />
          </b-col>
        </b-row>

        <!-- ANCHOR - Chặng thêm (VN1A) -->
        <!-- <template v-if="['VN1A'].includes(airlineSearch)">
          <b-row
            class="mb-75 justify-content-between"
            no-gutters
          >
            <b-col
              cols="12"
              md="9"
            >
              <b-form-input
                :value="resolveDataAddMoreFlight(indexFlight)"
                class="text-success fw-600 font-italic border-success"
                disabled
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex justify-content-end justify-content-md-around mt-50 mt-md-0"
            >
              <b-button
                variant="outline-danger"
                class="py-50 px-1 mr-75 mr-md-0 rounded-lg"
                @click="deleteAddMoreFlightHandle(indexFlight)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="primary"
                class="py-50 px-1 rounded-lg"
                @click="openModalAddMoreFlightHandle(selectedTrips[indexFlight], indexFlight)"
              >
                <feather-icon
                  class="mr-25"
                  icon="PlusIcon"
                />

                Thêm chặng
              </b-button>
            </b-col>
          </b-row>
        </template> -->
      </template>

      <b-form-input
        v-else
        value="Chưa chọn chuyến bay"
        class="text-danger font-italic"
        :style="`${isMobileView ? '' : `max-width: 60% !important`}`"
        disabled
      />
    </div>

    <div
      v-if="['VN1A'].includes(airlineSearch)"
      class="my-75 p-50 border-success rounded-lg"
    >
      <p class="mb-50 fw-700">
        Thêm hành trình:
      </p>

      <!-- ANCHOR - Chặng thêm (VN1A) -->
      <b-row
        v-for="(trip, tripIndex) of dataAddMoreFlight"
        :key="trip.tripUuid"
        class="mb-75 border"
        no-gutters
      >
        <b-col
          cols="auto"
          class="px-25 px-md-50 fw-700 d-flex-center"
        >
          {{ tripIndex + 1 }}:
        </b-col>
        <b-col cols="auto">
          <div
            v-for="segment of trip.segments"
            :key="segment.segmentUuid"
            class="text-info fw-700"
          >
            {{ `${convertISODateTime(segment.departureDate, segment.departureTimezone).time}-${convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).time}  ${convertISODateTime(segment.departureDate, segment.departureTimezone).dayAndMonth}  ${segment.airline}${segment.flightNumber} ${segment.bookingClass.code} ${segment.departure}${segment.arrival} ${segment.bookingClass.status}` }}
          </div>
        </b-col>

        <b-col
          cols="auto"
          class="d-flex-center"
        >
          <b-button
            variant="outline-danger"
            class="btn-icon ml-25 ml-md-1 p-25 p-md-50 rounded-lg"
            @click="deleteAddMoreFlightHandle(trip.tripUuid)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>

      <b-button
        variant="primary"
        class="py-50 px-1 rounded-lg"
        @click="openModalAddMoreFlightHandle"
      >
        <feather-icon
          class="mr-25"
          icon="PlusIcon"
        />
        Thêm hành trình
      </b-button>
    </div>

    <ModalAddMoreFlight :dataFlight.sync="dataFlight" />
  </div>
</template>

<script>
import {
  BFormInput,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'

import useBookingHandle from '@flightv2/useBookingHandle'
import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BButton,
    vSelect,
    ModalAddMoreFlight: () => import('@flightv2/result-class-booking/add-more-flight/ModalAddMoreFlight.vue'),
  },
  props: {
    getSearchFlightArray: {
      type: Array,
      default: () => [],
    },
    selectedTrips: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
    } = useBookingHandle()

    const {
      store,
      isEmpty,
      cloneDeep,
      dataTrips,
      toastError,
      airlineSearch,
      dataAddMoreFlight,
      delay,
      formatCurrency,
      convertISODateTime,
      classBookingOptions,
      handleSelectClassBooking,
      getSortTripBySegment,
    } = useClassBookingHandle()

    const statusSegment = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getStatusClassBooking`]))
    const bookingClassCodeSegment = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getBookingClassCode`]))

    function handleChangeStatus(value, indexFlight, indexSegment) {
      const statusDraft = cloneDeep(statusSegment.value)
      statusDraft[indexFlight][indexSegment] = value
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setStatusClassBooking`, statusDraft)
    }

    // NOTE: => Tìm classBooking trong trip theo classBooking đã chọn
    // => nếu có, đổi luôn data của segment trên hành trình đó
    // => k có, show toast error.
    function handleChangeClassBooking(value, indexFlight, segment, indexSegment) {
      try {
        const segmentInSearchData = dataTrips.value[indexFlight]?.trips?.find(t => t.segments.find(s => s.tripIndex === segment.tripIndex && s.uniqueSegmentId === segment.uniqueSegmentId))
        const classAvailInSegment = segmentInSearchData.segments[indexSegment]?.bookingClassAvail?.find(c => c.code === value)
        if (classAvailInSegment) {
          handleSelectClassBooking(segment, classAvailInSegment)
        } else {
          const bookingClassCodeDraft = cloneDeep(bookingClassCodeSegment.value)
          // bookingClassCodeDraft[indexFlight][indexSegment] = bookingClassCodeDraft[indexFlight][indexSegment]
          store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setBookingClassCode`, bookingClassCodeDraft)
          toastError({ content: 'Hạng vé đã chọn không có sẵn trên chặng bay hiện tại, vui lòng chọn lại!' })
        }
      } catch (error) {
        toastError({ content: 'Lỗi xử lý dữ liệu, vui lòng thực hiện lại!' })
        console.error('An error in handleChangeClassBooking:', { error })
      }
    }

    const dataFlight = ref(null)

    async function openModalAddMoreFlightHandle() {
      dataFlight.value = {
        departure: props.getSearchFlightArray[0].startPoint,
        arrival: props.getSearchFlightArray[0].endPoint,
        date: props.getSearchFlightArray[0].departDate,
      }
      await delay(200)
      this.$bvModal.show('modal-class-booking-add-more-flight')
    }

    function deleteAddMoreFlightHandle(uuid) {
      const rawData = cloneDeep(dataAddMoreFlight.value)
      store.dispatch('app-flight-v2/setAddMoreFlightClassBooking', rawData.filter(t => t.tripUuid !== uuid))
    }

    return {
      isEmpty,
      dataTrips,
      formatCurrency,
      convertISODateTime,

      statusSegment,
      handleChangeStatus,

      classBookingOptions,
      bookingClassCodeSegment,
      handleChangeClassBooking,

      getSortTripBySegment,
      airlineSearch,
      dataFlight,
      openModalAddMoreFlightHandle,
      deleteAddMoreFlightHandle,
      // resolveDataAddMoreFlight,
      dataAddMoreFlight,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.select_custom {
  ::v-deep .vs__dropdown-toggle {
    min-height: 37.98px !important;
    padding: 0px !important;

    .vs__search {
      width: 0px !important;
      padding: 0px !important;
    }

    .vs__selected {
      padding-left: .7rem !important;
      margin-top: 0 !important;
    }

    &.vs__open-indicator {
      margin: 0px !important;
    }
  }

  .flight_search {
    background-color: #166987 !important;
    color: white !important;
  }
}
</style>
